import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/entidades/login/auth.service';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { StoreService } from '../store.service';
import { Router } from '@angular/router';
import { ENV, VERSION } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading = false;
  public loginForm!: UntypedFormGroup;
  public logo?: string;
  public loginTitle?: string;
  public loginText?: string;
  public version = VERSION;
  public env = ENV;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private helperService: HelperService,
    private storeService: StoreService,
    private router: Router
  ) {}

  public async onSubmit() {
    try {
      if (this.loginForm.valid) {
        this.loading = true;
        await this.authService.login(
          this.loginForm.get('email')?.value,
          this.loginForm.get('password')?.value
        );
        this.loading = false;
      } else {
        this.helperService.notifWarn(
          'Debe ingresar un usuario y una contraseña'
        );
      }
    } catch (error) {
      console.error(error);
      this.helperService.notifError(error);
      this.loading = false;
    }
  }

  ngOnInit(): void {
    const token = this.storeService.getToken();
    if (token) {
      this.router.navigate(['/main']);
    }
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }
}
